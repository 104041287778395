<template>
  <div class="operating-w">
    <div class="banner-w">
      <img src="@/assets/image/yypt_bg.png" alt="" class="banner-w_img">
      <div class="banner-w_top">
        <div class="banner-w_top_l">
          <div class="banner-w_top_l_t">百年医养APP客户端</div>
          <div class="banner-w_top_l_p">居家医养一站式服务平台</div>
          <div class="banner-w_top_l_h">百年医养客户端APP，可为用户提供一键救援、健康体征全监测、安全区域监测、健康周报以及上门医养等服务，让长者在家中得到日常的专业护理和指导。</div>
          <div style="display: flex">
            <div class="banner-w_top_l_btn">
              <img src="@/assets/image/ios_web_icon.png" alt="" class="banner-w_top_l_btn_icon">
              <span>IOS下载</span>
            </div>
            <div class="banner-w_top_l_btn an">
              <img src="@/assets/image/android-icon.png" alt="" class="banner-w_top_l_btn_icon an">
              <span>安卓下载</span>
            </div>
          </div>
          <div class="banner-w_top_l_qrCode">
            <img src="@/assets/image/ewm01.png" class="banner-w_top_l_qrCode_img">
            <div class="banner-w_top_l_qrCode_t">扫一扫下载APP</div>
          </div>
        </div>
        <div class="banner-w_top_r">
          <img src="@/assets/image/yypt_bn_1.png" alt="" class="banner-w_imgs">
        </div>
      </div>
    </div>
    <div class="main-w">
      <div class="main-w_top">
        <div class="main-w_top_t">长者贴心的居家医养一站式服务平台</div>
        <div class="main-w_top_line"></div>
        <div class="main-w_top_p">百年医养APP（客户端）</div>
        <div class="main-w_top_h">可绑定我司全部智能产品（腕表、血压计、手环、体脂秤），为用户提供更全面的健康管理，如：24小时血压动态监测、一键救援、电子围栏、健康体征全监测、安全区域监测、健康周报以及医养上门等服务，让长者在家中得到日常的专业护理和指导。</div>
      </div>
    </div>
    <div class="main-w-img">
      <img src="@/assets/image/yypt_d_p1.png" alt="" class="yypt_d_p1">
      <div class="main-w-img-r">
        <img src="@/assets/image/yypt_icon.png" alt="" class="yypt_icon">
        <div class="main-w-img-r-c">
          <div class="main-w-img-r-c-t">功能全貌展示</div>
          <div class="main-w-img-r-c-btn">
            <div class="main-w-img-r-c-btn-d">
              <div class="main-w-img-r-c-btn-c">
                <i class="iconfont icon-xieya" style="color: #666666; font-size: 29px;"></i>
              </div>
              <div class="main-w-img-r-c-btn-t">血压</div>
            </div>
            <div class="main-w-img-r-c-btn-d">
              <div class="main-w-img-r-c-btn-c">
                <i class="iconfont icon-xinshuai" style="color: #666666; font-size: 29px;"></i>
              </div>
              <div class="main-w-img-r-c-btn-t">心率</div>
            </div>
            <div class="main-w-img-r-c-btn-d">
              <div class="main-w-img-r-c-btn-c">
                <i class="iconfont icon-xieyang" style="color: #666666; font-size: 29px;"></i>
              </div>
              <div class="main-w-img-r-c-btn-t">血氧</div>
            </div>
            <div class="main-w-img-r-c-btn-d">
              <div class="main-w-img-r-c-btn-c">
                <i class="iconfont icon-tiwen" style="color: #666666; font-size: 29px;"></i>
              </div>
              <div class="main-w-img-r-c-btn-t">体温</div>
            </div>
            <div class="main-w-img-r-c-btn-m">
              <div class="main-w-img-r-c-btn-c">
                <i class="iconfont icon-jiankangguanli" style="color: #666666; font-size: 29px;"></i>
              </div>
              <div class="main-w-img-r-c-btn-t">健康管理</div>
            </div>
            <div class="main-w-img-r-c-btn-d">
              <div class="main-w-img-r-c-btn-c">
                <i class="iconfont icon-anquandingwei" style="color: #666666; font-size: 29px;"></i>
              </div>
              <div class="main-w-img-r-c-btn-t">安全定位</div>
            </div>
          </div>
          <div class="main-w-img-r-c-p">百年医养APP（客户端）可接收各类智能养老设备，汇总血压、心率、血氧、睡眠、运动等数据，进行AI大数据分析，对用户的心脑血管健康进行事前干预、预警、救援以及日常健康管理，从而全面守护长者的健康安全。
          </div>
        </div>
      </div>
    </div>
    <div class="main-w-img-blood">
      <div class="main-w-img-blood-l">
        <img src="@/assets/image/yypt_icon.png" alt="" class="yypt_icon">
        <div class="main-w-img-blood-l-c">
          <div class="main-w-img-blood-l-c-t">血压健康管理</div>
          <div class="main-w-img-blood-l-c-p">
            <span class="main-w-img-blood-l-c-p c">血压动态监测：</span>
            <span>全新血压动态监测技术，独家B-Pumping 血压监测引擎，建立个性化血压算法模型，连续24小时测量血压更准、更持续。</span>
          </div>
          <div class="main-w-img-blood-l-c-p">
            <span class="main-w-img-blood-l-c-p c">血压健康分析：</span>
            <span>通过问卷调查、个性化健康数据模型AI分析，对用户血压健康状况进行全面分析，提醒、帮助用户管理血压。</span>
          </div>
          <div class="main-w-img-blood-l-c-p">
            <span class="main-w-img-blood-l-c-p c">血压动态分析报告：</span>
            <span>通过对高血压患者及疑似高血压患者进行24小时内的血压监测，以及专业化的数据呈现分析，有效避免“白大衣现象”，发现“隐匿性高血压”，了解血压变化趋势，包含血压夜间下降情况以及晨峰血压。</span>
          </div>
        </div>
      </div>
      <img src="@/assets/image/yypt_d_p2.png" alt="" class="yypt_d_p2">
    </div>
    <div class="main-w-img-healthy">
      <img src="@/assets/image/yypt_d_p3.png" alt="" class="yypt_d_p3">
      <div class="main-w-img-healthy-l">
        <img src="@/assets/image/yypt_icon.png" alt="" class="yypt_icon">
        <div class="main-w-img-healthy-l-c">
          <div class="main-w-img-healthy-l-c-t">健康周报</div>
          <div class="main-w-img-healthy-l-c-p">
            <span>由百年医养专家团队根据用户一周的血压趋势、心率趋势、温度趋势、睡眠情况、运动频率等完整数据进行分析，给予用户精准、专业的周报，辅助用户对自身血压规律和变化进行了解，给予血压管理有效的建议。</span>
          </div>
        </div>
      </div>
    </div>
    <div class="main-w-img-warn">
      <div class="main-w-img-warn-l">
        <img src="@/assets/image/yypt_icon.png" alt="" class="yypt_icon">
        <div class="main-w-img-warn-l-c">
          <div class="main-w-img-warn-l-c-t">健康风险预警</div>
          <div class="main-w-img-warn-l-c-p">
            <span>关注每个细小的健康异动,通过智能云端与三甲医院主任医师深度合作,对心血脑血管风险进行全面分析,并提供风险预警提醒服务.</span>
          </div>
        </div>
      </div>
      <img src="@/assets/image/yypt_d_p4.png" alt="" class="yypt_d_p4">
    </div>
    <div class="main-w-img-history">
      <div class="main-w-img-history-r">
        <img src="@/assets/image/yypt_icon.png" alt="" class="yypt_icon">
        <div class="main-w-img-history-r-c">
          <div class="main-w-img-history-r-c-t">电子围栏、历史轨迹</div>
          <div class="main-w-img-history-r-c-p">可设定多个电子围栏,指定用户活动范围,用户离开指定范围，APP实时自动预警，告知用户亲属位置动态及时找回。此外，通过智能设备的多重定位，以及PDR融合定位和AR识别算法加持，APP可以记录实现活动轨迹的定位和回放。
          </div>
        </div>
      </div>
    </div>
    <div class="main-w-img-culture">
      <div class="main-w-img-culture-r">
        <img src="@/assets/image/yypt_icon.png" alt="" class="yypt_icon">
        <div class="main-w-img-culture-r-c">
          <div class="main-w-img-culture-r-c-t">广场舞文化</div>
          <div class="main-w-img-culture-r-c-p">可设定多个电子围栏,指定用户活动范围,用户离开指定范围，APP实时自动预警，告知用户亲属位置动态及时找回。此外，通过智能设备的多重定位，以及PDR融合定位和AR识别算法加持，APP可以记录实现活动轨迹的定位和回放。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { index } from "@/utils/mixins";

import { myRequest } from "@/request/index";
import { data_data_list } from "@/request/api/base";
export default {
  mixins: [index],

  props: {},
  components: {},
  created() {},

  computed: {},

  mounted() {},

  data() {
    return {
      tabActive: "",
    };
  },

  methods: {},
};
</script>


<style lang="scss" scoped>
  @function torem($px){//$px为需要转换的字号
    @return $px / 30px * 1rem; //100px为根字体大小
  }
.operating-w {
  background-color: #fff;
  .banner-w {
    position: relative;
    &_img {
      width: 100%;
      height: auto;
    }
    &_top {
      display: flex;
      align-items: center;
      &_l {
        position: absolute;
        top: 10%;
        left: 19%;
        &_t {
          font-size: torem(42px);
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          line-height: torem(69px);
          text-shadow: 0px torem(4px) torem(8px) rgba(169, 169, 169, 0.35);
          margin-bottom: 54px;
        }
        &_p {
          font-size: torem(24px);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          margin-bottom: torem(13px);
        }
        &_h {
          width: torem(405px);
          height: auto;
          font-size: torem(14px);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: torem(25px);
          margin-bottom: torem(45px);
        }
        &_btn {
          width: torem(176px);
          height: torem(61px);
          display: flex;
          align-items: center;
          justify-content: center;
          background: #333333;
          border-radius: torem(31px);
          color: #ffffff;
          font-size: torem(16px);
          &.an {
            margin-left: torem(41px);
            background: #42C253;
          }
          &_icon {
            width: torem(29px);
            height: torem(35px);
            margin-right: torem(10px);
            &.an {
              width: torem(32px);
              height: torem(36px);
            }
          }
        }
        &_qrCode {
          width: torem(130px);
          height: torem(156px);
          background: #FFFFFF;
          border-radius: torem(5px);
          padding: torem(10px) torem(9px);
          text-align: center;
          margin-top: torem(33px);
          &_img {
            width: torem(112px);
            height: torem(112px);
          }
          &_t {
            font-size: torem(14px);
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            margin-top: torem(6px);
          }
        }
      }
      &_r {
        position: absolute;
        top: 0;
        right: 19%;
        &_imgs {
          /*position: absolute;*/
        }
      }
    }
  }
  .banner-w_imgs {
    width: torem(407px);
    height: torem(782px);
    margin-top: torem(30px);
  }
  .main-w {
    margin-top: torem(184px);
    padding-bottom: torem(41px);
    &_top {
      text-align: center;
      &_t {
        font-size: torem(32px);
        font-family: Microsoft YaHei;
        font-weight: 300;
        color: #666666;
        margin-bottom: torem(35px);
      }
      &_line {
        width: torem(60px);
        height: torem(8px);
        background: #333333;
        margin: torem(21px) auto;
      }
      &_p {
        font-size: torem(32px);
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
        margin: 0 auto;
        margin-bottom: torem(22px);
      }
      &_h {
        width: torem(771px);
        height: auto;
        font-size: torem(16px);
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        margin: 0 auto;
      }
    }
  }
  .main-w-img {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: torem(97px);
    padding-bottom: torem(74px);
    background: linear-gradient(5deg, #F7FBFE, #FFFFFF);
    &-r {
      margin-left: torem(75px);
      &-c {
        margin-top: torem(15px);
        &-t {
          font-size: torem(36px);
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-bottom: torem(60px);
        }
        &-btn {
          text-align: center;
          display: flex;
          align-items: center;
          margin-bottom: torem(74px);
          &-c {
            width: torem(49px);
            height: torem(49px);
            border: torem(1px) solid #CCCCCC;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-bottom: torem(11px);
          }
          &-t {
            font-size: torem(16px);
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
          &-d {
            margin-right: torem(18px);
          }
          &-m {
            margin-right: torem(10px);
          }
        }
        &-p {
          width: torem(340px);
          font-size: torem(16px);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          text-align: left;
          letter-spacing: torem(1px);
        }
      }
    }
  }
  .yypt_d_p1 {
    width: torem(667px);
    height: auto;
  }
  .yypt_icon {
    width: torem(95px);
    height: torem(12px);
  }
  .yypt_d_p2 {
    width: torem(1193px);
    height: auto;
  }
  .yypt_d_p3 {
    width: torem(1193px);
    height: auto;
  }
  .yypt_d_p4 {
    width: torem(1127px);
    height: auto;
  }
  .main-w-img-blood {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: torem(97px);
    padding-bottom: torem(74px);
    background: #ffffff;
    &-l {
      position: absolute;
      left: 20%;
      top: 16%;
      &-c {
        margin-top: torem(15px);
        &-t {
          font-size: torem(36px);
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-bottom: torem(36px)
        }
        &-btn {
          text-align: center;
          display: flex;
          align-items: center;
          margin-bottom: torem(74px);
          &-c {
            width: torem(49px);
            height: torem(49px);
            border: torem(1px) solid #CCCCCC;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-bottom: torem(11px);
          }
          &-t {
            font-size: torem(16px);
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
          &-d {
            margin-right: torem(18px);
          }
          &-m {
            margin-right: torem(10px);
          }
        }
        &-p {
          width: torem(396px);
          font-size: torem(16px);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-bottom: torem(30px);
          &.c {
            color: #333333;
            font-weight: bold;
          }
        }
      }
    }
  }
  .main-w-img-healthy {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: torem(97px);
    padding-bottom: torem(74px);
    background: #F7FBFE;
    &-l {
      position: absolute;
      right: 21.5%;
      top: 22%;
      &-c {
        margin-top: torem(15px);
        &-t {
          font-size: torem(36px);
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-bottom: torem(38px);
        }
        &-btn {
          text-align: center;
          display: flex;
          align-items: center;
          margin-bottom: torem(74px);
          &-c {
            width: torem(49px);
            height: torem(49px);
            border: torem(1px) solid #CCCCCC;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-bottom: torem(11px);
          }
          &-t {
            font-size: torem(16px);
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
          &-d {
            margin-right: torem(18px);
          }
          &-m {
            margin-right: torem(10px);
          }
        }
        &-p {
          width: torem(396px);
          font-size: torem(16px);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-bottom: torem(30px);
          &.c {
            color: #333333;
            font-weight: bold;
          }
        }
      }
    }
  }
  .main-w-img-warn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: torem(97px);
    padding-bottom: torem(74px);
    background: #fff;
    &-l {
      position: absolute;
      left: 22%;
      top: 22%;
      &-c {
        margin-top: torem(15px);
        &-t {
          font-size: torem(36px);
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-bottom: torem(38px);
        }
        &-btn {
          text-align: center;
          display: flex;
          align-items: center;
          margin-bottom: torem(74px);
          &-c {
            width: torem(49px);
            height: torem(49px);
            border: torem(1px) solid #CCCCCC;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            margin-bottom: torem(11px);
          }
          &-t {
            font-size: torem(16px);
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
          &-d {
            margin-right: torem(18px);
          }
          &-m {
            margin-right: torem(10px);
          }
        }
        &-p {
          width: torem(396px);
          font-size: torem(16px);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          margin-bottom: torem(30px);
          &.c {
            color: #333333;
            font-weight: bold;
          }
        }
      }
    }
  }
  .main-w-img-history {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: torem(97px);
    padding-bottom: torem(74px);
    background: url('../assets/image/yypt_d_p5.png') center center no-repeat;
    width: 100%;
    height: torem(960px);
    position: relative;
    &-r {
      position: absolute;
      top: 43%;
      right: 22%;
      &-c {
        margin-top: torem(15px);
        &-t {
          font-size: torem(36px);
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-bottom: torem(36px);
        }
        &-p {
          width: torem(340px);
          font-size: torem(16px);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          text-align: left;
          letter-spacing: torem(1px);
        }
      }
    }
  }
  .main-w-img-culture {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: torem(97px);
    padding-bottom: torem(74px);
    background: url('../assets/image/yypt_d_p6.png') center center no-repeat;
    width: 100%;
    height: torem(960px);
    position: relative;
    &-r {
      position: absolute;
      top: 43%;
      left: 22%;
      &-c {
        margin-top: torem(15px);
        &-t {
          font-size: torem(36px);
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #333333;
          margin-bottom: torem(36px);
        }
        &-p {
          width: torem(340px);
          font-size: torem(16px);
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          text-align: left;
          letter-spacing: torem(1px);
        }
      }
    }
  }
}
</style>
